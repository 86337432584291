<template>
    <div class="main">
        <div class="w12">
            <div class="title">
                企业认证
            </div>
            <div class="step">
                <el-steps :active="step" align-center>
                    <el-step title="填写信息" description=""></el-step>
                    <el-step title="平台审核" description=""></el-step>
                    <el-step title="开放权限" description=""></el-step>
                </el-steps>
            </div>
            <div v-if="step == 1">
                <div class="formList">
                    <div class="form_content">
                        <div class="form">
                            <div class="form_title">
                                企业名称：
                            </div>
                            <div class="form_inp">
                                <input maxlength="50" v-model="param.qyName" placeholder="请输入" />
                            </div>
                        </div>
                        <div class="form">
                            <div class="form_title">
                                法人姓名：
                            </div>
                            <div class="form_inp">
                                <input v-model="param.frName" placeholder="请输入" />
                            </div>
                        </div>
                        <div class="form">
                            <div class="form_title">
                                联系方式：
                            </div>
                            <div class="form_inp">
                                <input v-model="param.contactWay" type="number" placeholder="选填" class="contact"/>
                            </div>
                        </div>
                        <div class="form" style="align-items: flex-start;">
                            <div class="form_title">
                                上传营业执照：
                            </div>
                            <div class="form_img">
                                <el-upload class="avatar-uploader" :action="uploadPath+'/system/front/upload_file'"
                                    :show-file-list="false" :on-success="handleSuccess" :before-upload="beforeUpload">
                                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <!-- <input type="file" @change="handleFileUpload">
                                <img :src="imageUrl" v-if="imageUrl"> -->
                                <!-- <div>
                                    <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload">
                                    <div style="position: relative;" class="preview" v-if="imageUrl">
                                        <img :src="imageUrl">
                                        <div></div>
                                        <div style="position: absolute;top: 0;right: 0;background-color:red;cursor: pointer; width: 16px;height: 16px;font-size:14px;text-align:center;line-height: 16px;"
                                            @click="removeImage">×</div>
                                    </div>
                                    <div class="upload" v-else>
                                        <div @click="chooseFile"
                                            style="width: 200px;height: 290px;color:#999999;line-height: 290px;text-align:center;font-size: 50px;cursor: pointer;">
                                            + </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>


                </div>
                <div class="agree">
                    <div class="imgs" @click="agreeShow = !agreeShow">
                        <img v-if="agreeShow" src="@/assets/yes.png" alt="">
                        <img v-else src="@/assets/no.png" alt="">
                    </div>

                    我已阅读并同意<span @click="jumpLaw('使用条款')">《使用条款》</span>和<span @click="jumpLaw('隐私声明')">《隐私声明》</span>
                </div>
            </div>
            <div v-if="step == 2">
                <div v-if="userInfo.authStatus == 3">
                    <div class="shenhe">
                        <img src="../../assets/person/shenheno.png" alt="">
                    </div>
                    <div class="shenhe_tip">
                        审核失败，请重新填写信息
                    </div>
                    <div class="shenhe_reason">
                        <!-- 这里是审核失败的原因 -->
                        {{ userInfo.failure }}
                    </div>

                </div>
                <div v-if="userInfo.authStatus == 1">
                    <div class="shenhe" style="margin-bottom:100px;">
                        <img src="../../assets/person/shenhe.png" alt="">
                    </div>

                </div>

            </div>
            <div class="container_form" v-if="step == 3">
                <div class="quanxian">
                    <div class="quanxian_ico" style="text-align:center;">
                        <img style="width: 40%;" src="@/assets/person/quanxian.png" alt="">
                    </div>
                    <div class="tongguo">
                        企业认证通过，已向您开放以下权限
                    </div>
                    <div class="quanxian_other">
                        <div class="everyQuanxian flex_center_a">
                            <div class="other_content">
                                发布仓库出租信息
                            </div>
                        </div>
                        <div class="everyQuanxian flex_center_a">
                            <div class="other_content">
                                发布人才招聘信息
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="login" v-if="step == 1">
                <button @click="submit">提交申请</button>
            </div>
            <div class="login" v-if="step == 2 && userInfo.authStatus == 3">
                <button @click="step = 1">去修改</button>
            </div>

        </div>




    </div>
</template>
<script>
export default {
    data() {
        let that = this;
        return {
          uploadPath: process.env.VUE_APP_BASE_API,
            imageUrl: '',
            step: 1,
            agreeShow: false,
            param: {
                qyName: '',
                frName: '',
                contactWay: '',
                yyzz: ''
            },
            userInfo: {},
            imageUrl: null,
            dialogImageUrl: '',
            dialogVisible: false
        };
    },

    mounted() {
      // alert(123)
    },
    created() {
        this.getUser()
    },
    methods: {
        jumpLaw(data) {
            // this.$router.push({ path: "/law", query: { selectName: data } });
            let page = this.$router.resolve({ path: "/law", query: { selectName: data } });
            window.open(page.href, '_blank')
        },
        goXieyi(type) {
            this.$router.push({
                path: "/xieyi",
                query: {
                    type: type
                },
            });
        },
        handleSuccess(res, file, fileList) {
            this.imageUrl = URL.createObjectURL(file.raw)
            console.log(res);
            if (res.code == 200) {
                this.param.yyzz = res.data.url
            }

        },
        beforeUpload(file) {
            console.log("aaaaaaaaaa", file);
            const isJPG = file.type === 'image/jpeg'
            const isPNG = file.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 5
            if (!isJPG && !isPNG) {
                // this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
                this.$message({
                    message: "上传图片只能是 JPG/PNG 格式!",
                    type: 'error',
                    offset: 100
                });
                return false
            }
            if (!isLt2M) {
                this.$message({
                    message: "上传图片大小不能超过 5M",
                    type: 'error',
                    offset: 100
                });
                // this.$message.error('上传头像图片大小不能超过 5MB!')
                return false
            }
            return true
        },
        // chooseFile() {
        //     this.$refs.fileInput.click()
        // },
        // async handleFileUpload(event) {
        //     const file = event.target.files[0]
        //     console.log(file);
        //     let data = await this.$api.base.uploadFile(file)
        //     console.log(data);
        //     const reader = new FileReader()
        //     reader.readAsDataURL(file)
        //     reader.onload = () => {
        //         this.imageUrl = reader.result
        //     }
        // },
        // removeImage() {
        //     this.imageUrl = ''
        // },
        // 获取用户数据
        async getUser() {
            let user = await this.$api.base.getUser()
            this.userInfo = user.info
            console.log(this.userInfo);
            if (this.userInfo.authStatus == 0) {
                this.step = 1
            } else if (this.userInfo.authStatus == 1 || this.userInfo.authStatus == 3) {
                this.step = 2
            } else if (this.userInfo.authStatus == 2) {
                this.step = 3
            }
        },
        async submit() {
            if (this.agreeShow == false) {
                this.$message({
                    message: "请同意使用条款和隐私声明",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.qyName == '') {
                this.$message({
                    message: "请填写企业名称",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.frName == '') {
                this.$message({
                    message: "请填写法人名称",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (this.param.contactWay != "") {
                if (this.param.contactWay.length != 11) {
                    this.$message({
                        message: "联系方式格式不正确",
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
            }


            if (this.param.yyzz == '') {
                this.$message({
                    message: "请上传营业执照",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            console.log(this.param);
            let param = this.param
            let a = await this.$api.base.applyApprove(param)
            console.log(a);
            if (a == null) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                    offset: 100
                });
                this.getUser()
            } else if (a.code == -9999) {
                this.$message({
                    message: a.msg,
                    type: 'error',
                    offset: 100
                });
            }


        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPrediv(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleAvatarSuccess(res, file, fileList) {
            console.log(res);
            console.log(fileList);
            let arr = []
            for (let i = 0; i < fileList.length; i++) {
                let obj = {
                    fileName: fileList[i].response.data.fileName,
                    filePath: fileList[i].response.data.filePath
                }
                arr[arr.length] = obj


            }
            this.param.yyzz = JSON.stringify(arr)
            // this.imageUrl = URL.createObjectURL(file.raw);
            // console.log(this.imageUrl);
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 5;

            // if (!isJPG) {
            //     this.$message({
            //         message: "上传图片只能是 JPG 格式",
            //         type: 'error',
            //         offset: 100
            //     });
            // }
            if (!isLt2M) {
                this.$message({
                    message: "上传图片大小不能超过 5M",
                    type: 'error',
                    offset: 100
                });
            }
            return isJPG && isLt2M;
        }


    },
};
</script>
<style scoped lang="less">

.contact::-webkit-outer-spin-button,
.contact::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/deep/ .el-dialog {

    border-radius: 10px !important;
}

.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 290px;
    // border-radius: 50%;
    // overflow: hidden;
    background-color: #f5f7fa;
}

.avatar {
    width: 100%;
    height: 100%;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
}

/deep/ .v-modal {
    z-index: 1000 !important;
}

.container_form {
    padding: 50px 30px 200px 30px;

    .form_one {
        padding: 0 30px 30px 30px;
        background-color: #FFFFFF;
        border-radius: 20px;
        margin-bottom: 30px;

        .form_every {
            border-bottom: 2px solid #E0E0E0;
            padding: 30px 0;

            .title {
                font-weight: bold;
                color: #333333;
                margin-bottom: 20px;

                text {
                    color: #D00000;
                }
            }

            .uploadCover {
                margin: 30px auto;
                width: 400px;
                height: 240px;
            }

            .title_input {
                font-size: 28px;
                // color: #999999;
            }
        }
    }

    .shenhe {
        .shenhe_ico {
            width: 384px;
            height: 384px;
            margin: 0 auto;
        }

        .shenhezhong {
            color: #999999;
            text-align: center;
        }

        .reason {
            width: 510px;
            margin: 0 auto;
            margin-top: 40px;
            padding: 30px;
            background: #F7F8FA;
            border-radius: 20px;
            font-size: 26px;
            color: #D00000;
        }
    }

    .quanxian {
        .quanxian_ico {
            width: 480rpx;
            height: 480rpx;
            margin: 0 auto;
        }

        .tongguo {
            color: #999999;
            text-align: center;
            margin: 20rpx 0 60rpx 0;
        }

        .quanxian_other {
            padding: 0 70rpx;

            .everyQuanxian {

                .other_content {
                    text-align: center;
                    background-color: #F7F8FA;
                    border-radius: 20px;
                    padding: 30px 40px;
                    color: #333333;
                    margin-top: 30px;

                }
            }
        }
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

/deep/ .el-step__head.is-finish {
    color: #00B079;
    border: #00B079;
}

/deep/ .el-step__title.is-finish {
    color: #00B079;
    font-weight: 600;
}

.el-step__title {
    font-size: 20px;
}

.main {
    padding-top: 100px;

    .title {

        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        text-align: center;
        margin-bottom: 50px;
    }

    .step {}

    .formList {

        .form_content {
            margin-top: 50px;

            .form {
                width: 60%;
                margin: 0 auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 30px;

                .form_title {
                    width: 160px;
                    text-align: right;
                    font-size: 18px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21px;
                }

                .form_inp {
                    width: 60%;

                    input {
                        padding-left: 20px;
                        width: 100%;
                        height: 48px;
                        border-radius: 10px 10px 10px 10px;
                        border: 1px solid #CCCCCC;
                    }

                    input:focus {
                        outline: none;
                    }

                }


                .form_img {
                    // width: 200px;
                    // height: 290px;
                    border-radius: 10px 10px 10px 10px;
                    padding: 10px 20px;
                    border: 1px solid #CCCCCC;

                    img {
                        width: 200px;
                        height: 290px;
                    }
                }
            }
        }

    }

    .agree {

        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .imgs {
            margin-right: 4px;
        }

        img {
            width: 18px;
            height: 18px;
        }

        span {
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #00B079;
            cursor: pointer;
        }
    }

    .login {

        margin-top: 11px;
        padding-bottom: 50px;
        display: flex;
        justify-content: center;

        button {
            margin: 0 auto;
            width: 30%;
            height: 48px;
            background: #00B079;
            border-radius: 4px 4px 4px 4px;
            border: none;
            font-size: 20px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
    }

    .shenhe {
        text-align: center;
        margin-top: 50px;

        img {
            width: 200px;
            height: 200px;
        }
    }

    .shenhe_tip {
        text-align: center;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #CCCCCC;
        line-height: 28px;
        margin: 10px 0 50px 0;
    }

    .shenhe_reason {
        margin: 0 auto;
        width: 540px;
        background: #F5F5F5;
        border-radius: 10px 10px 10px 10px;
        padding: 30px;
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #F23838;
        line-height: 36px;
        margin-bottom: 40px;
    }
}
</style>
